.table {
  .sort-arrows {
    color: #9aa0ac;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .align-content {
    display: flex;
    align-items: flex-end;
  }
}

.table-fix-head {
  tbody {
    display: block;
    overflow-y: scroll;
    height: 53vh;
  }

  thead {
    display: block;
  }

  th,
  td {
    width: 10vw;
  }
}
