.header.header-sticky {
  z-index: 1010;
}

.card-header {
  font-weight: bold;
}

.label {
  font-weight: bold;
}

.button {
  font-weight: bold;
}

.react-select-container {
  &.is-invalid {
    .react-select__control { // stylelint-disable-line selector-class-pattern
      border-color: #e55353;
    }

    .react-select__indicator-separator { // stylelint-disable-line selector-class-pattern
      background-color: #e55353;
    }
  }
}

.version-info {
  color: rgb(201, 201, 201) !important;
  text-align: center;
  font-size: smaller;
  flex: 0 0 35px;
  -webkit-flex: 0 0 35px;
}

.stacked-modal {
  z-index: 1060;

  + .modal-backdrop {
    z-index: 1055;
  }
}

.bridge-card {
  height: 100%;
  width: 100%;
}

.image-fit {
  max-height: 100%;
  object-fit: contain;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* Adjust this to match the aspect ratio of your images */
  overflow: hidden;
}

.aspect-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* Adjust this to match the aspect ratio of your images */
  overflow: hidden;
}

.aspect-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}